import styles from './contact.module.css';
import { useState, useReducer } from 'react';
import separator from '../assets/images/white_baroque_separator.svg';
import backArrow from '../assets/images/back_white.svg';
import resetCross from '../assets/images/reset_white.svg';


const Contact = (props) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const form = document.querySelector('form')

    const formReducer = (state, action) => {
        switch (action.type) {
        
        case 'NAME_INPUT_BLUR':
            if (action.val === '') {
                return { ...state, nameIsValid: false, nameMessage: 'Name muss ausgefüllt sein' }
         } else if (action.val.length > 255) {
           return {...state, nameIsValid: false, nameMessage:'Name is too long'}
         } else return {...state, nameIsValid: true, nameMessage: ''}
            break;

         case 'EMAIL_INPUT_BLUR': {
            if (!action.val.includes('@')) {
                return {...state, emailIsValid: false, emailMessage: 'Ungültiger email Format'}
            } else return{...state, emailIsValid: true, emailMessage: ''}
            break;
        }
        case 'PHONE_INPUT_BLUR': {
            const regexPhone = /^[0-9+()\- ]*$/;
            if(!regexPhone.test(action.val)){
                return{...state, phoneIsValid: false, phoneMessage: 'Üngultige Telefonnummer'};
            } else return {...state, phoneIsValid: true, phoneMessage: ''}
            break;
        }

        case 'MESSAGE_INPUT_BLUR': {
            if (action.val ==='') {
                return {...state, messageIsValid: false, messageMessage: 'Bitte geben Sie eine Nachricht ein'}
            } else return {...state, messageIsValid: true, messageMessage: ''};
            break;

        }

        case 'NAME_CHANGE': {
            return {...state, name: action.val, nameIsValid: true, nameMessage: '' };
            break;
        }

        case 'EMAIL_CHANGE': {
            return {...state, email: action.val, emailIsValid: true, emailMessage: ''};
        }
            break;

        case 'PHONE_CHANGE': {
                return {...state, phone: action.val, phoneIsValid: true, phoneMessage: ''};
            }
            break;


        case 'MESSAGE_CHANGE': {
                return {...state, message: action.val, messageIsValid: true, messageMessage: ''};
            }
            break;

        case 'default' : return;

        }
    }

    
    const [formInput, formInputDispatch] = useReducer(formReducer, {
                                                                    name: '',
                                                                    nameIsValid: true,
                                                                    nameMessage: '',
                                                                    email: '',
                                                                    emailIsValid: true,
                                                                    emailMessage: '',
                                                                    phone: '',
                                                                    phoneIsValid: true,
                                                                    phoneMessage: '',
                                                                    message: '',
                                                                    messageIsValid: true,
                                                                    messageMessage: ''
                                                                });

    const [formResponseMessage, setFormResponseMessage] = useState('Waiting...');


    const onNameChangeHandler = (event) => {
        formInputDispatch({type: 'NAME_CHANGE', val: event.target.value});
    }

    const onEmailChangeHandler = (event) => {
        formInputDispatch({type: 'EMAIL_CHANGE', val: event.target.value});
    }

    const onPhoneChangeHandler = (event) => {
        formInputDispatch({type: 'PHONE_CHANGE', val: event.target.value});
    }

    const onMessageChangeHandler = (event) => {
        formInputDispatch({type: 'MESSAGE_CHANGE', val: event.target.value});
    }

    const onNameBlurHandler = (event) => {
        formInputDispatch({type:'NAME_INPUT_BLUR', val: event.target.value})
    }

    const onEmailBlurHandler = (event) => {
        formInputDispatch({type:'EMAIL_INPUT_BLUR', val: event.target.value})
    }

    const onPhoneBlurHandler = (event) => {
        formInputDispatch({type:'PHONE_INPUT_BLUR', val: event.target.value})
    }

    const onMessageBlurHandler = (event) => {
        formInputDispatch({type:'MESSAGE_INPUT_BLUR', val: event.target.value})
    }

    const onFormResetHandler = () => {
        form.reset();
    }

    const onFormSubmitHandler = async () => {

        const response = await fetch(`${API_URL}/api/controller/mailer.php`,
        {method: 'POST',
        body: "name=" + formInput.name + "&email=" + formInput.email + "&phone=" + formInput.phone + "&message=" + formInput.message,
        headers: {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
        });
        if (response.ok) {
            const responseData = await response.json();
            setFormResponseMessage(responseData.messages[0]);
        } else setFormResponseMessage('Server Error: mail not sent');
        setTimeout(() => {setFormResponseMessage('Waiting...')}, 5001)
    }



    return(
        <article className={styles.container}
        aria-label="Contact Form">
            <h3 className={styles.title}>Kontakt</h3>

            <div className={styles.content}>

            <form className={styles.contactForm}>
            <span className={styles.error}>{formInput.nameMessage}</span>
            <label htmlFor='name' className={styles.label}>Name</label>
            <input name='name' type='text' className={formInput.nameIsValid ? styles.input: styles.input_error} required onBlur={onNameBlurHandler}  onChange={onNameChangeHandler}></input>
            <span className={styles.error}>{formInput.emailMessage}</span>
            <label htmlFor='email' className={styles.label}>E-mail</label>
            <input name='email' type='email' className={formInput.emailIsValid ? styles.input: styles.input_error} onBlur ={onEmailBlurHandler} onChange={onEmailChangeHandler}></input>
            <span className={styles.error}>{formInput.phoneMessage}</span>

            <label htmlFor='phone' className={styles.label}>Fon</label>
            <input name='phone' type='text' className={formInput.phoneIsValid ? styles.input: styles.input_error} onBlur = {onPhoneBlurHandler} onChange={onPhoneChangeHandler}></input>

            <span className={styles.error}>{formInput.messageMessage}</span>

            <label htmlFor='message' className={styles.label}>Ihre Nachricht</label>
            <textarea name='message' rows='4' cols='10'  className={formInput.messageIsValid ? styles.textarea: styles.textarea_error} onBlur = {onMessageBlurHandler} onChange={onMessageChangeHandler} required></textarea>
            </form>
            
            <address className={styles.addressContainer}>
                <img src={separator} alt='separator' className={styles.upperSeparator} />

                <div className={styles.address}>
                   <p>Dr. phil.</p> 
                   <p>MAIK GOTH</p> 
                   <p>Adalbertstr. 8</p> 
                   <p>59199 Bönen</p> 
                   <p>Tel. +49 163 4756000</p>
                <a href='mailto:maik.goth@rub.de?subject="Mail aus der Maik Goth Website"'>maik.goth@rub.de</a>
                </div>
                <img src={separator} alt='separator' className={styles.lowerSeparator} />

            </address>

            </div>
            <aside className={styles.buttons}>
            <button type="submit" onClick={onFormSubmitHandler} className={styles.button}>
            <p>SENDEN</p>
            <img className={styles.submit} src ={backArrow} alt='submit' />
            </button>
            <button type="reset" onClick={onFormResetHandler} className={styles.button}>
            <p>RESET</p>
            <img src ={resetCross} alt='reset' />
            </button>
            <button className={styles.button} onClick={props.onModalClose} style={{tranform: 'translateX(-32px)', marginRight:'20px'}}>
            <p>ZURÜCK</p>
            <img src ={backArrow} alt='back' />
            </button>
            
            </aside>
            <div className={(formResponseMessage === 'Waiting...') ? styles.hidden : styles.formResponseMessage}>{formResponseMessage}</div>
        </article>
    );

}


export default Contact;