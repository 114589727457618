import styles from './singleEvent.module.css';
import CrookedSeparator from './UI/CrookedSeparator';

const SingleEvent = (props) => {

    
        if (props.link != null) {
         return (
             <div className={styles.events_content} aria-label="Event Entry">
         <h5 className={styles.datum} aria-label="Date">{props.datum}</h5>
         <h4 className={styles.titel} aria-label="Title">{props.titel}</h4>
         <p className={styles.untertitel} aria-label="Subtitle">{props.untertitel}</p>
         <p className={styles.ort} aria-label="Event Location">{props.ort}</p>
         <p className={styles.link} aria-label="Event link"><a href={props.link}>Zum Event &rarr;</a></p>
         <CrookedSeparator color='white' width='60%' left='20%'/>
         </div>
         )
        } else  { 
             return (
         <div className={styles.events_content} aria-label="event entry">
         <h5 className={styles.datum} aria-label="Date">{props.datum}</h5>
         <h4 className={styles.titel} aria-label="Title">{props.titel}</h4>
         <p className={styles.untertitel} aria-label="Subtitle">{props.untertitel}</p>
         <p className={styles.ort} aria-label="Event Location">{props.ort}</p>
         <CrookedSeparator color='white' width='60%' left='20%'/>

         </div>
         )
        }
    }

export default SingleEvent;