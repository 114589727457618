import styles from './sidemenu.module.css';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import decorator from '../assets/images/sidemenu_decorator.png';
import downwards_decorator from '../assets/images/sidemenu_decorator_downwards.png';

import CrookedSeparator from './UI/CrookedSeparator';

const SideMenu = (props) => {
    const [isHovered, setIsHovered] = useState();

    const navigate = useNavigate();

    const onMenuItemEnterHandler = (link) => {
        if (link !== props.isActive) setIsHovered(link);
    };

    const onMenuItemLeaveHandler = (link) => {
        if (link !== props.isActive) setIsHovered('');
    };

    const handleKeyDown = (e, onClick) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            onClick();
        }
    };

    const activeLink1ClickHandler = () => {
        props.onLink1Click();
        setIsHovered('');
    };

    const activeLink2ClickHandler = () => {
        props.onLink2Click();
        setIsHovered('');
    };

    const activeLink3ClickHandler = () => {
        props.onLink3Click();
        setIsHovered('');
    };

    const activeLink4ClickHandler = () => {
        props.onLink4Click();
        setIsHovered('');
    };

    return (
        <aside className={styles.container}>
            {(props.viewport > 430 && props.viewportHeight > 430) && (
                <div className={styles.decorator_left}>
                    <img src={decorator} alt="menu decorator" />
                </div>
            )}

            <nav className={styles.sideMenuBox} aria-label="Hauptnavigation">
                <ul className={styles.sideMenu}>
                    {/* Home */}
                    <li
                        className={styles.sideMenuItem}
                        role="link"
                        tabIndex={0}
                        aria-current={props.isActive === 'link1' ? 'page' : undefined}
                        style={props.homeLinkColor && { color: props.homeLinkColor }}
                        onMouseEnter={() => onMenuItemEnterHandler('link1')}
                        onMouseLeave={() => onMenuItemLeaveHandler('link1')}
                        onClick={() => navigate('../home')}
                        onKeyDown={(e) => handleKeyDown(e, () => navigate('../home'))}
                    >
                        Home
                    </li>
                    <li className={styles.activeLink}>
                        {(((props.viewport > 430 && props.viewportHeight > 430) && isHovered === 'link1') || props.isActive === 'link1') &&
                            <CrookedSeparator
                                top={props.viewport >= 540 && props.viewport <= 896 ? '0' : '-12px'}
                                left={(props.viewport > 430 || props.viewportHeight > 430) ? '0' : '12px'}
                                color={props.viewport > 430 ? 'black' : 'white'}
                                width={props.viewport > 430 ? '60%' : '80%'}
                            />
                        }
                    </li>

                    {/* Link 1 */}
                    <li
                        className={styles.sideMenuItem}
                        role="link"
                        tabIndex={0}
                        aria-current={props.isActive === 'link2' ? 'page' : undefined}
                        key = {2}
                        onMouseEnter={() => onMenuItemEnterHandler('link2')}
                        onMouseLeave={() => onMenuItemLeaveHandler('link2')}
                        onClick={activeLink1ClickHandler}
                        onKeyDown={(e) => handleKeyDown(e, activeLink1ClickHandler)}
                    >
                        {props.link1}
                    </li>
                    <li className={styles.activeLink}>
                        {(((props.viewport > 430 && props.viewportHeight > 430) && isHovered === 'link2') || props.isActive === 'link2') &&
                            <CrookedSeparator
                                top={props.viewport >= 540 && props.viewport <= 896 ? '0' : '-12px'}
                                left={(props.viewport > 430 || props.viewportHeight > 430) ? '0' : '12px'}
                                color={props.viewport > 430 ? 'black' : 'white'}
                                width="60%"
                            />
                        }
                    </li>

                    {/* Link 2 */}
                    <li
                        className={styles.sideMenuItem}
                        role="link"
                        tabIndex={0}
                        aria-current={props.isActive === 'link3' ? 'page' : undefined}
                        onMouseEnter={() => onMenuItemEnterHandler('link3')}
                        onMouseLeave={() => onMenuItemLeaveHandler('link3')}
                        onClick={activeLink2ClickHandler}
                        onKeyDown={(e) => handleKeyDown(e, activeLink2ClickHandler)}
                    >
                        {props.link2}
                    </li>
                    <li className={styles.activeLink}>
                        {(((props.viewport > 430 && props.viewportHeight > 430) && isHovered === 'link3') || props.isActive === 'link3') &&
                            <CrookedSeparator
                                top={props.viewport >= 540 && props.viewport <= 896 ? '0' : '-12px'}
                                left={(props.viewport > 430 || props.viewportHeight > 430) ? '0' : '12px'}
                                color={props.viewport > 430 ? 'black' : 'white'}
                                width="60%"
                            />
                        }
                    </li>

                    {/* Link 3 */}
                    <li
                        className={styles.sideMenuItem}
                        role="link"
                        tabIndex={0}
                        aria-current={props.isActive === 'link4' ? 'page' : undefined}
                        onMouseEnter={() => onMenuItemEnterHandler('link4')}
                        onMouseLeave={() => onMenuItemLeaveHandler('link4')}
                        onClick={activeLink3ClickHandler}
                        onKeyDown={(e) => handleKeyDown(e, activeLink3ClickHandler)}
                    >
                        {props.link3}
                    </li>
                    <li className={styles.activeLink}>
                        {(((props.viewport > 430 && props.viewportHeight > 430) && isHovered === 'link4') || props.isActive === 'link4') &&
                            <CrookedSeparator
                                top={props.viewport >= 540 && props.viewport <= 896 ? '0' : '-12px'}
                                left={(props.viewport > 430 || props.viewportHeight > 430) ? '0' : '12px'}
                                color={props.viewport > 430 ? 'black' : 'white'}
                                width="60%"
                            />
                        }
                    </li>

                    {/* Link 4 */}
                    <li
                        className={styles.sideMenuItem}
                        role="link"
                        tabIndex={0}
                        aria-current={props.isActive === 'link5' ? 'page' : undefined}
                        onMouseEnter={() => onMenuItemEnterHandler('link5')}
                        onMouseLeave={() => onMenuItemLeaveHandler('link5')}
                        onClick={activeLink4ClickHandler}
                        onKeyDown={(e) => handleKeyDown(e, activeLink4ClickHandler)}
                    >
                        {props.link4}
                    </li>
                    <li className={styles.activeLink}>
                        {((((props.viewport > 820 && props.viewportHeight > 430)) && isHovered === 'link5') || props.isActive === 'link5') &&
                            <CrookedSeparator
                                top={props.viewport >= 540 && props.viewport <= 896 ? '0' : '-12px'}
                                left={(props.viewport > 430 || props.viewportHeight > 430) ? '0' : '12px'}
                                color={props.viewport > 430 ? 'black' : 'white'}
                                width="60%"
                            />
                        }
                    </li>
                </ul>
            </nav>
        </aside>
    );
};

export default SideMenu;
