import styles from './musician.module.css';

import { useState } from 'react';

import ReactDOM from 'react-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import SideMenu from '../components/SideMenu';
import SocialMenu from '../components/SocialMenu';
import BackToTop from '../components/UI/BackToTop';
import StandardImageList from '../components/UI/StandardImageList';
import ImageModal from '../components/UI/ImageModal';
import Backdrop from '../components/UI/Backdrop';
import backToTopArrow from '../assets/images/back_to_top.svg';
import mockingbird from '../assets/audio/mockingbird_excerpts.mp3';
import bild1 from '../assets/images/gallery_musician/1020891.jpg';
import bild2 from '../assets/images/gallery_musician/FEU0019.jpg';
import bild3 from '../assets/images/gallery_musician/FEU0020.jpg';
import bild4 from '../assets/images/gallery_musician/LO2_6647.JPG';
import bild5 from '../assets/images/gallery_musician/Maik_Goth_Promopic.JPG';
import bild6 from '../assets/images/gallery_musician/MGbackWhitsun2017.jpg';
import bild7 from '../assets/images/gallery_musician/P1050678.JPG';
import bild8 from '../assets/images/gallery_musician/P1050725.JPG';
import bild9 from '../assets/images/gallery_musician/PB245800_DxO.jpg';
import bild10 from '../assets/images/gallery_musician/PB247367_DxO.jpg';
import bild11 from '../assets/images/gallery_musician/rmu-murat-kayi.jpg';
import bild12 from '../assets/images/gallery_musician/1020923.jpg';




import bitmap4 from '../assets/images/bitmap_musician.png';
import separator from '../assets/images/article_separator.svg';
import polaris from '../assets/images/mg_musiker_polaris_cover.jpg';

const Musician = (props) => {

  const API_URL = process.env.REACT_APP_API_URL;
    
    const backdrop = document.getElementById('backdrop');

    const imageViewer = document.getElementById('imageViewer');

    const [modalIsOn, setModalIsOn] = useState(false);
    const [displayedImageInModal, setDisplayedImageInModal] = useState(null);
    const [activePage, setActivePage] = useState('link2');

    const [expandableBlock1Expanded, setExpandableBlock1Expanded] = useState(false);
    const [expandableBlock2Expanded, setExpandableBlock2Expanded] = useState(false);
    const [expandableBlock3Expanded, setExpandableBlock3Expanded] = useState(false);
    const [expandableBlock4Expanded, setExpandableBlock4Expanded] = useState(false);
    const [expandableBlock5Expanded, setExpandableBlock5Expanded] = useState(false);
    const [expandableBlock6Expanded, setExpandableBlock6Expanded] = useState(false);
    
    
    
    
    // Handlers for expandable content in article4
    
    const onContent1ExpandHandler = () => setExpandableBlock1Expanded(!expandableBlock1Expanded);
    const onContent2ExpandHandler = () => setExpandableBlock2Expanded(!expandableBlock2Expanded);
    const onContent3ExpandHandler = () => setExpandableBlock3Expanded(!expandableBlock3Expanded);
    const onContent4ExpandHandler = () => setExpandableBlock4Expanded(!expandableBlock4Expanded);
    const onContent5ExpandHandler = () => setExpandableBlock5Expanded(!expandableBlock5Expanded);
    const onContent6ExpandHandler = () => setExpandableBlock6Expanded(!expandableBlock6Expanded);


    const checkActivePage = () => {
      const contentMain = document.querySelector('main');
      const anchor1 = document.querySelector('.article1').getBoundingClientRect();
      const anchor2 = document.querySelector('.article2').getBoundingClientRect();
      const anchor3 = document.querySelector('.article3').getBoundingClientRect();
      const anchor4 = document.querySelector('.article4').getBoundingClientRect();

      const anchorTop = props.viewportHeight > 430 ? (props.viewportHeight > 1366 ? 260 : 600 ) : 161;

      if(anchor4.top <= anchorTop) {
        setActivePage('link5')
      }else if(anchor3.top <= anchorTop) {
        setActivePage('link4') 
        }else if(anchor2.top <= anchorTop) {
        setActivePage('link3')
        }else if(anchor1.top <= anchorTop){
          setActivePage('link2');
        } else setActivePage('');
      }

      


  


    const imageClickHandler = (image) => {
      setModalIsOn(true);
      setDisplayedImageInModal(image);
      }

      const onClickOnX = () => {
        setModalIsOn(false);
    }

    const imageChangeHandler = (image) => {
        setDisplayedImageInModal(image);
    }

    const itemData = [
        {
          img: bild1,
          title: 'Maik Goth in Concert 1',
        },
        {
          img: bild2,
          title: 'Maik Goth singing',
        },
        {
          img: bild3,
          title: 'Maik Goth playing Guitar',
        },
        {
          img: bild4,
          title: 'Maik Goth singing and playing Guitar',
        },
        {
          img: bild5,
          title: 'Maik Goth Portait',
        },
        {
          img: bild6,
          title: 'Maik Goth Promo Picture',
        },
        {
          img: bild7,
          title: 'Maik Goth in the garden with Guitar',
        },
        {
          img: bild8,
          title: 'Maik Goth singing loud',
        },
        {
          img: bild9,
          title: 'Maik Goth Guitar Profile',
        },
        {
          img: bild10,
          title: 'Maik Goth on stage',
        },
        {
          img: bild11,
          title: 'Maik Goth with silver guitar',
        },
        {
          img: bild12,
          title: 'Maik Goth with band',
        },
      ];


const onGoToArticle1 = () => {
        const contentMain = document.querySelector('main');
        const anchor1 = document.querySelector('.article1');
        const anchorTop = props.viewportHeight > 430 ? 260 : 159;

        let a = contentMain.scrollTop + anchor1.getBoundingClientRect().top - anchorTop;
        contentMain.scrollTo({
          top: a,
          left: 0,
         behavior: 'smooth'
        });
      }        

const onGoToArticle2 = () => {

        const contentMain = document.querySelector('main');
        const anchor2 = document.querySelector('.article2');
        const anchorTop = props.viewportHeight > 430 ? 260 : 159;

        let b = contentMain.scrollTop + anchor2.getBoundingClientRect().top - anchorTop;
        contentMain.scrollTo({
          top: b,
          left: 0,
         behavior: 'smooth'
        });
      }        

const onGoToArticle3 = () => {

        const contentMain = document.querySelector('main');
        const anchor3 = document.querySelector('.article3');

        const anchorTop = props.viewportHeight > 430 ? 260 : 159;

        let c = contentMain.scrollTop + anchor3.getBoundingClientRect().top - anchorTop ;
        contentMain.scrollTo({
          top: c,
          left: 0,
         behavior: 'smooth'
        });
      }  



      const onGoToArticle4 = () => {
        const contentMain = document.querySelector('main');
        const anchor4 = document.querySelector('.article4');
      
        const anchorTop = props.viewportHeight > 430 ? 260 : 159;
      
        // Offset für das letzte Menupunkt damit der Cursor springt
        const additionalOffset = 20; 
      
        let d = contentMain.scrollTop + anchor4.getBoundingClientRect().top - anchorTop + additionalOffset;
        contentMain.scrollTo({
          top: d,
          left: 0,
          behavior: 'smooth'
        });
      }
         

const onBackToTopHandler = () => {

  const contentMain = document.querySelector('main');
  contentMain.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}





return (
    <main className={styles.main} onScroll={checkActivePage}>
       
        {props.viewport > 430 &&
        <SideMenu 
        link1='MUSIKER'
        link2='TÄTIGKEIT' 
        link3='DISKOGRAPHIE' 
        link4='MEDIA' 
        onLink1Click = {onGoToArticle1} 
        onLink2Click = {onGoToArticle2} 
        onLink3Click = {onGoToArticle3} 
        onLink4Click = {onGoToArticle4}
        isActive = {activePage}
        viewport = {props.viewport}
        viewportHeight = {props.viewportHeight}
        />}

        {(props.viewport > 430 && props.viewportHeight> 430) && <SocialMenu />}

        <Header 
        viewport={props.viewport}
        viewportHeight = {props.viewportHeight}
        color={props.viewport>430 ? 'rgba(127,181,64,1)' : 'black' }>
          Musiker
          </Header>
<img src ={bitmap4} alt = "Maik Goth, musician" className = {styles.picture} />

<section className={styles.content}> 
<article className='article1'>
    <h3 className={styles.articleTitle} style={{marginBottom: '60px'}}>Über mich als<span className={styles.titleShift}>MUSIKER</span></h3>
    <div className={styles.articleContent}>Ich bin Sänger, Musiker (Gitarre, Mandoline, Ukulele, Bağlama, Keys) und Komponist, der in vielen musikalischen Stilrichtungen zu Hause ist (Rock, Pop, Prog, Jazz, Folk, Global Music). Über drei Jahrzehnte habe ich Erfahrung als Singer-Songwriter (zumeist auf englisch, bisweilen auf deutsch), als Komponist von Musicals, szenischer Musik und Programmmusik für Vernissagen, als musikalischer Leiter und Bandleader gesammelt. Dabei treibt mich die Freude an, neue Musik zu schreiben, mit anderen Musikern zusammenzuarbeiten und in verschiedenen Ensembles auf die Bühne zu gehen. Als Mitglied der Murat Kayi Band habe ich regelmäßig bei der <em>Liederlounge</em> live auf WDR 5 mit herausragenden Musiker:innen musiziert, Festivalbühnen mit internationalen Musiker:innen geteilt. Im Februar 2023 habe ich mein selbst produziertes Soloalbum <em>Polaris — Songs of Night and Wonder</em> veröffentlicht (Retter des Rock Records). <br /> <br />Hier findet Ihr meine Projekte und Bands — also schaut rein! <br /><a href = "mailto:maik.goth@rub.de">
      <br />
      <strong>Schreibt mir gern!</strong></a></div>
<BackToTop onBackToTop = {onBackToTopHandler}/>
</article>

<img src={separator} alt="separator line" className={styles.articleSeparator}/>

<article className='article2'>
  <h3 className={styles.articleTitleCenter}>Tätigkeit</h3>
      <div className={styles.articleContent}>
        
        <div className={styles.expandableTitle} 
        onClick = {onContent1ExpandHandler}
        onKeyDown={onContent1ExpandHandler} 
        role="button" 
        tabIndex="0"
        aria-expanded={expandableBlock1Expanded}
        aria-controls="Projects-section">
          <img src ={backToTopArrow} className={expandableBlock1Expanded ? styles.arrowSmaller180g: styles.arrowSmaller90g} alt="Icon to expand Projects Menu"/> <h4>PROJEKTE</h4>
        </div>

        <div className={expandableBlock1Expanded ? styles.expandableContent1Expanded : 
        styles.expandableContent1}>
          <div className={styles.werk}>
            <img src ={polaris} className={styles.thumbnail} alt="Polaris - Songs of night and wonder"></img>
          <p>
            <strong>POLARIS - SONGS OF NIGHT AND WONDER</strong>
            </p>
            </div>
          <p>
          <em>Polaris - Songs of Night and Wonder</em> sind zwölf mitreißende, Funken schlagende Lieder von inneren Krisen und äußeren Zwängen, von der Suche nach neuen Wegen und der Kraft der Musik. Frei und einfallsreich bewegen sie sich zwischen Rock- und Popstilen. Zu Bass, Schlagzeug, Stromgitarre und dem ausdrucksvollen Timbre der Stimme gesellen sich Keyboards, Synthesiser und Fender Rhodes, Mandoline, Saz. Das ist Musik, die an Peter Gabriel und Jeff Buckley, an David Bowie und Patrick Wolf denken lässt. <br />
          Dazu gibt es einen kleinen, feinen Bildband mit Illustrationen internationaler Künstler:innen, teils traditionell, teils Glitch Art, Diorama, Performanzfoto, Digitalkunst. POLARIS — SONGS OF NIGHT & WONDER sind zwölf Lieder zum Staunen und Schauen, energiegeladen, außergewöhnlich, bewegend. 
          </p>

          <p>
            <strong>BLOODLIGHT</strong>
            </p>

          <p>
          <em>Bloodlight</em> ist ein aufregendes Albumprojekt, das uns auf eine labyrinthinische Reise zwischen Erlebtem und Imaginiertem, Traum und Wirklichkeit, Abgründigkeit und der Hoffnung auf Erlösung mitnimmt. Dabei wandeln sich die Kompositionen von Rock zu Industrial, von Elektro zur Musique concrète, greifen Songs und Instrumentalstücke ineinander. Das klingt, als träfen sich David Bowie und David Lynch mit Scott Walker und Nicolas Winding Refn zu einem langen Abendessen, um gemeinsam eine phantasmagorische Geschichte zu erzählen. Aus dem Projekt geht auch das instrumentale Album <em>Scenes from the Other Side of Midnight hervor</em>. Geplante Veröffentlichung ist 2024.
          </p>

          <p>
            <strong>UNDER ONE SUN - MUSIC BEYOND BORDERS</strong>
            </p>

          <p>
          In diesem transkulturellen Musikprojekt kollaboriere ich mit internationalen Musiker:innen aus verschiedenen Kulturkreisen. Dabei entstehen im Dialog verschiedener musikalischer Traditionen (z.B. Fernasiens, Anatoliens, Westafrikas und Skandinaviens) gemeinsam neue Lieder, Instrumentalstücke und Soundcollagen. So hören wir Instrumente wie Bağlama (Türkei), Guqin (China) oder Kora (Westafrika) und Traditionen wie mongolischen Kehlgesang in neuen Zusammenhängen, erfahren die Geschichten anderer Kulturen. Denn Musik öffnet die Herzen und weitet den Blick auf andere Erfahrungswelten. Ab Anfang 2024 werden im Abstand von drei bis vier Monaten die musikalischen Arbeiten auf einer Reihe von EPs veröffentlicht. 
          </p>

        </div>

        <div className={styles.expandableTitle} 
        onClick = {onContent2ExpandHandler}
        onKeyDown={onContent2ExpandHandler} 
        role="button" 
        tabIndex="0"
        aria-expanded={expandableBlock2Expanded}
        aria-controls="Ensembles-section">
          <img src ={backToTopArrow} className={expandableBlock2Expanded ? styles.arrowSmaller180g: styles.arrowSmaller90g}/> 
        <h4>ENSEMBLES</h4>
        </div>
        <div className={expandableBlock2Expanded ? styles.expandableContent2Expanded : 
        styles.expandableContent2}>
          <div className={styles.werk}>
            <img src={polaris} className={styles.thumbnail} alt="Polaris - Band"></img>
          <p><strong>POLARIS</strong></p>
          <p>Aus dem Album <em>Polaris</em> ist eine Band geworden! Frei und einfallsreich bewegt sich unser Quartett zwischen Rock- und Popstilen, bietet große Melodien mit überraschenden Wendungen. Während Jan Jerig am Bass und Moritz Bleif am Schlagzeug filigran bis wuchtig grooven, gebe ich an der Stromgitarre mal ein hartes Rockbrett zum besten, mal streiche ich sanft über die Saiten. Zum Timbre der Stimme gesellt sich Jason Merse an Keyboards und Synthesizer, bringt Licht und Farben in die Arrangements. Let it rock!
          </p>
          </div>
        <p>
          <strong>MAIK GOTH & MURAT KAYI DUO</strong></p>
          <p>
            Murat Kay und ich sind alte Freunde und spielen seit 1992 im Duo. Dabei bedienen wir uns aus einem schier unermesslichen Fundus von Stücken verschiedener Genres sowie aus eigener Feder. Und bringen sie mit Spielfreude und Lust an der Improvisation auf die Bühne.<br />
            <a href= "https://www.murat-kayi.de/projects-3" relative = "path"><strong>Hier</strong></a> geht es zur Duo-Website.
          </p>

          <p><strong>FURIOSO</strong></p>
          <p>Ein dynamisches Duo mit dem Geigenvirtuosen Jonas Liesenfeld. Leidenschaftlich und gefühlvoll, spielfreudig und gut gelaunt spielen wir Musik von Bach bis Balkan, von Brasilien bis Bruckner. Und alles dazwischen und darüber hinaus, auch unsere eigenen Kompositionen. Furioso ist Musik ohne Grenzen! Im Sommer veröffentlichen wir unsere erste EP.
          </p>

          <p><strong>MOCKINGBIRD</strong></p>
          <p>Gemeinsam mit den virtuosen und erfahrenen Jazzern Winfried Jung (Saxophon, Klarinette und Querflöte) und Max Jalaly (Kontrabass) präsentiere ich Songs und Instrumentalstücke aus eigener Feder sowie handverlesene Klassiker. Spielfreudig und mit viel Groove bietet die Combo eine abwechslungsreiche Mischung an Stilen vom schnellen Swing zum eleganten Swing Manouche, vom pulsierenden Blues zum Cool Jazz und bringt das Publikum zum Mitswingen.
          </p>

          <p><strong>CHEZ MU</strong></p>
          <p>Seit einhundert Jahren gibt es ihn: den Gypsy-Jazz, den Django Reinhardt wie kein zweiter geprägt hat. Die Musiker von Chez Mu mit Murat Kay (Dobro), David Zeich (Gitarre), Maik Goth (Gitarre), Jonas Liesenfeld (Geige) und Max Jalaly (Kontrabass) haben sich dieser Musik verschrieben und spielen mitreißend und virtuos Klassiker und Neuentdeckungen des Genres. Mal rhythmisch-ausgelassen, mal verträumt-melancholisch swingen sie sich durch ein mitreißendes Programm. 
          </p>
            
        </div>

        <div className={styles.expandableTitle} 
        onClick = {onContent3ExpandHandler}
        onKeyDown={onContent3ExpandHandler} 
        role="button" 
        tabIndex="0"
        aria-expanded={expandableBlock3Expanded}
        aria-controls="Legacy-section">
          <img src ={backToTopArrow} className={expandableBlock3Expanded ? styles.arrowSmaller180g: styles.arrowSmaller90g} alt="Icon to expand legacy menu"/> 
        <h4>LEGACY</h4>
        </div>
        <div className={expandableBlock3Expanded ? styles.expandableContent3Expanded : 
        styles.expandableContent3}>
          <p><strong>MAIK GOTH BAND</strong></p>
          <p>Eine fröhliche Folkband mit Thomas Hecking (diatonisches Knopfakkordion), Susanne Kamp (Flutes und Whistles), Michael Kamp (Bodhrán) und mir (Gesang, Gitarre, Mandoline). Zu unserem Repertoire gehören selbst geschriebene transatlantische Lieder und Tunes. Ehrenmitglied Dave Jackson, mein Väterchen Folk, hat uns bisweilen an Gesang, Gitarre, Mandoline und Mundharmonika zur Seite gestanden.
          </p>
        <p>
          <strong>WÜDNEKS ERBEN</strong></p>
          <p>
          Eine wunderbare Balfolk-Band, die traditionelle skandinavische, bretonische und, ja!, westfälische Tunes auf Tanzfestivals spielte. Gemeinsam mit Franziska Urton und Richmud Rollenbeck an der Geige, Johannes Rollenbeck am Kontrabass und mir selbst an der Gitarre haben wir Bühnen mit hochkarätigen tollen internationalen Musiker:innen geteilt. Dazu gehörten zum Beispiel Floating Sofa Quartet (Dänemark, Schweden, Finnland) und Wouter en de Draak (Niederlande). Anstatt eingeübte Arrangements zu spielen, haben wir über die erlernten Tunes improvisiert — und auch völlig neue auf der Bühne erfunden. 
          </p>

          <p><strong>MURAT KAYS BAND</strong></p>
          <p>Groovige Hardfolkband des großartigen Murat Kayı, dem Meister an Dobro und Resonatorgitarre. Murats erdige deutsche Lieder erzählen Geschichten, wie das Leben sie schreibt: rau und direkt, und nehmen das Publikum von der ersten Note an gefangen. 
          </p>

          
            
        </div>
        
        
    </div>

    <BackToTop onBackToTop = {onBackToTopHandler}/>

</article>

<img src={separator} alt="separator line" className={styles.articleSeparator}/>

<article className='article3'>
    <h3 className={styles.articleTitleCenter}>DISKOGRAPHIE</h3>
    <div className={styles.articleContent}>
      <ul>
        <li>
          Polaris — Songs of Night and Wonder (Retter des Rock Records, 2023) - 
 <a href = "https://www.retterdesrock.com/maik-goth/"><strong>Hier</strong> zum Stück</a>
  </li>

<li><em>in Vorbereitung</em><br/>
Bloodlight, Album, geplante VÖ: Oktober 2024<br />
Under One Sun — Music Beyond Borders, EP-Reihe, VÖ ab Sommer 2024<br />

</li>

<li>als Gast
  <ul>
    <li>Murat Kayi, Heimatlieder (Album, 2013)</li>
    <li>Murat Kayi, »Selbst für Dich« (Single, 2022)</li>
    <li>Murat Kayi, Le Swing Glissant (EP, 2023) - <a href="—> https://www.murat-kayi.de/projects-3-1"><strong>Hier</strong></a> zum Album</li>
	
  </ul>
  </li>

<li>Projekt »Rock Your Mental Health«<br />
Für den International Mental Health Day am 10. Oktober 2022 mit Hendrik Huthoff (Berlin) entstandenes Benefizstück »Rock Your Mental Health«. Unser knackiger Rocksong macht auf das Thema Mental Health aufmerksam, zeigt Lösungswege auf und soll Bettroffenen Kraft geben, ihrer Krankheit die Stirn zu bieten. Die Erlöse gehen an die Organisation <a href="https://www.irrsinnig-menschlich.de">»Irrsinnig menschlich e.V.«</a>.<br />
  <a href="https://www.youtube.com/watch?v=V-Ou4K_WTe4"> <strong>Hier</strong></a> zum Album.
 </li>
 </ul>
 </div>
<BackToTop onBackToTop = {onBackToTopHandler}/>


</article>

<article className='article4'>

<h3 className={styles.articleTitleCenter}>MEDIA</h3>

<div className={styles.expandableTitle} 
        onClick={onContent4ExpandHandler}
        onKeyDown={onContent4ExpandHandler} 
        role="button" 
        tabIndex="0"
        aria-expanded={expandableBlock4Expanded}
        aria-controls="Audio-Section">
                      <img src={backToTopArrow} className={expandableBlock4Expanded ? styles.arrowSmaller180g : styles.arrowSmaller90g} alt="Icon to expand Menu 'Audio'"/> 
                      <h4>AUDIO</h4>
                  </div>
                  <div className={expandableBlock4Expanded ? styles.expandableContent1Expanded : styles.expandableContent1}>
            <p>Auszüge aus MOCKINGBIRD</p>
                  <audio controls>
  <source src={mockingbird} type="audio/mpeg" />
  Your browser does not support the audio tag.
</audio>
                  </div>

                  <div className={styles.expandableTitle} onClick={onContent5ExpandHandler}>
                      <img src={backToTopArrow} className={expandableBlock5Expanded ? styles.arrowSmaller180g : styles.arrowSmaller90g} alt="Icon to expand Menu 'Video'"/> 
                      <h4>VIDEO</h4>
                  </div>
                  <div className={expandableBlock5Expanded ? styles.expandableContent5Expanded : styles.expandableContent5}>
                  <iframe src="https://www.youtube.com/embed/YW-_s8uRmFU?si=72Jcx5de2Q6hZz2J" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  <iframe src="https://www.youtube.com/embed/fHyhEN3___o?si=6Jxxj6Ibhc4bDSYs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  <iframe src="https://www.youtube.com/embed/iwDSO2QRmwY?si=TW4Lsg5WugdkF-pZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  <iframe src="https://www.youtube.com/embed/VIa7f0DZaDo?si=QlH1koJBP_HlM8gm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  <iframe src="https://www.youtube.com/embed/zZTjJO5go5Y?si=MB7jMazv19hWL5W4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  <iframe src="https://www.youtube.com/embed/VKW33rbYV9Y?si=zpIDPHc_ra6mtDQQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  <iframe src="https://www.youtube.com/embed/k3Khf30bebE?si=gx-V7j2WoJYaztOd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  <iframe src="https://www.youtube.com/embed/S4xT3HXQw1k?si=nRKU-ZJvgbTwXyJz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/84HKIMlA7GE?si=mVxUf6Ylr23XoRfT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                  </div>

                  <div className={styles.expandableTitle} onClick={onContent6ExpandHandler}>
                      <img src={backToTopArrow} className={expandableBlock6Expanded ? styles.arrowSmaller180g : styles.arrowSmaller90g} alt="Icon to expand Image Gallery"/> 
                      <h4>BILDERGALERIE</h4>
                  </div>
                  <div className={expandableBlock6Expanded ? styles.expandableContent6Expanded : styles.expandableContent6}>
                  {modalIsOn && ReactDOM.createPortal(<Backdrop isOn={modalIsOn}/>, backdrop) }
      {modalIsOn && ReactDOM.createPortal(
          <ImageModal 
              itemData = {itemData} 
              imageInModal = {itemData[displayedImageInModal].img} 
              imageId={displayedImageInModal}
              howManyImages = {itemData.length}
              onModalClose = {onClickOnX} 
              onArrowClick = {imageChangeHandler}/>,
          imageViewer)}
<StandardImageList onImageClick = {imageClickHandler} itemData = {itemData} />
                  </div>



</article>

<Footer color={props.viewport > 430 ? 'rgba(127,181,64,1)' : 'transparent'}/>

</section>

{(props.viewport <= 430 && props.viewportHeight > 430) &&
        <SideMenu 
        link1='ABOUT' 
        link2='ARBEIT' 
        link3='RELEASES' 
        link4='MEDIA' 
        homeLinkColor = '#fff245'
        onLink1Click = {onGoToArticle1} 
        onLink2Click = {onGoToArticle2} 
        onLink3Click = {onGoToArticle3} 
        onLink4Click = {onGoToArticle4}
        isActive = {activePage}
        viewport = {props.viewport}
        />}

</main>
    );
}

export default Musician;