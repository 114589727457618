import styles from './events.module.css';

import { useEffect, useContext } from 'react';
import dataContext from '../store/dataContext';

import backArrow from '../assets/images/back_white.svg';

import SingleEvent from './SingleEvent';



const Events = (props) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const dataCtx = useContext(dataContext);

    const getEvents = async () => { 
                
        const response = await fetch(`${API_URL}/api/controller/eventTasks.php`, {
        method: 'GET'
          });
        const allData = await response.json();
        console.log(allData.messages[0]);
        
        if(allData.messages[0] === 'Events successfully retrieved') {
              dataCtx.getEvents(allData.data);
            }
            else if(allData.messages[0] === 'No events found') {
                dataCtx.getEvents([{id: null, titel: 'No events planned yet', link: null, untertitel: 'Stay tuned!', ort: null}]);
            } else alert('Error retrieving events');
        };

    useEffect(
                () => {
                getEvents();
                }, [dataCtx]
            );


    const eventsDisplay = dataCtx.events.map(event => <SingleEvent 
                                            datum={event.datum}
                                            link={event.link}
                                            titel = {event.titel}
                                            untertitel = {event.untertitel}
                                            ort = {event.ort} 
                                            />)

    return(
        <div className={styles.eventContainer} aria-label="Events">
                    <h3 className={styles.events_title}>EVENTS</h3>

        <div className={styles.events_list}>

            {eventsDisplay}
            <div className={styles.back} onClick={props.onModalClose}>
            <p>BACK</p>
            <img src ={backArrow} alt='back' />
        </div>
        </div>
        
        </div>
    );


}

export default Events;

