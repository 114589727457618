import styles from './scholar.module.css';

import { useState } from 'react';

import ReactDOM from 'react-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import SideMenu from '../components/SideMenu';
import SocialMenu from '../components/SocialMenu';
import BackToTop from '../components/UI/BackToTop';
import StandardImageList from '../components/UI/StandardImageList';
import ImageModal from '../components/UI/ImageModal';
import Backdrop from '../components/UI/Backdrop';
import backToTopArrow from '../assets/images/back_to_top.svg';


import bitmap2 from '../assets/images/bitmap_philologist.png';
import separator from '../assets/images/article_separator.svg';
import monsters from '../assets/images/mg_wissenschaft_monsters.jpg';
import chaucer from '../assets/images/mg_wissenschaft_vice.jpg';
import medievalia from '../assets/images/mg_wissenschaft_mh.jpg';
import drama from '../assets/images/mg_wissenschaft_compdr_innen.jpg';


const Scholar = (props) => {const backdrop = document.getElementById('backdrop');

const imageViewer = document.getElementById('imageViewer');

const [modalIsOn, setModalIsOn] = useState(false);
const [displayedImageInModal, setDisplayedImageInModal] = useState(null);
const [activePage, setActivePage] = useState('link2');

// States for expandable content in article4
const [expandableBlock1Expanded, setExpandableBlock1Expanded] = useState(false);
const [expandableBlock2Expanded, setExpandableBlock2Expanded] = useState(false);
const [expandableBlock3Expanded, setExpandableBlock3Expanded] = useState(false);
const [expandableBlock4Expanded, setExpandableBlock4Expanded] = useState(false);
const [expandableBlock5Expanded, setExpandableBlock5Expanded] = useState(false);
const [expandableBlock6Expanded, setExpandableBlock6Expanded] = useState(false);




// Handlers for expandable content in article4

const onContent1ExpandHandler = () => setExpandableBlock1Expanded(!expandableBlock1Expanded);
const onContent2ExpandHandler = () => setExpandableBlock2Expanded(!expandableBlock2Expanded);
const onContent3ExpandHandler = () => setExpandableBlock3Expanded(!expandableBlock3Expanded);
const onContent4ExpandHandler = () => setExpandableBlock4Expanded(!expandableBlock4Expanded);
const onContent5ExpandHandler = () => setExpandableBlock5Expanded(!expandableBlock5Expanded);
const onContent6ExpandHandler = () => setExpandableBlock6Expanded(!expandableBlock6Expanded);

  const checkActivePage = () => {
    const contentMain = document.querySelector('main');
    const anchor1 = document.querySelector('.article1').getBoundingClientRect();
    const anchor2 = document.querySelector('.article2').getBoundingClientRect();
    const anchor3 = document.querySelector('.article3').getBoundingClientRect();
    const anchor4 = document.querySelector('.article4').getBoundingClientRect();

    const anchorTop = props.viewportHeight > 430 ? (props.viewportHeight > 1366 ? 260 : 600 ) : 161;

    if(anchor4.top <= anchorTop + 100) {
      setActivePage('link5')
    }else if(anchor3.top <= anchorTop) {
      setActivePage('link4') 
      }else if(anchor2.top <= anchorTop) {
      setActivePage('link3')
      }else if(anchor1.top <= anchorTop){
        setActivePage('link2');
      } else setActivePage('');
    }

  const imageClickHandler = (image) => {
    setModalIsOn(true);
    setDisplayedImageInModal(image);
    }

    const onClickOnX = () => {
      setModalIsOn(false);
  }

  const imageChangeHandler = (image) => {
      setDisplayedImageInModal(image);
  }

  const itemData = [
      {
        img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
        title: 'Breakfast',
      },
      {
        img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
        title: 'Burger',
      },
      {
        img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
        title: 'Camera',
      },
      {
        img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
        title: 'Coffee',
      },
      {
        img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
        title: 'Hats',
      },
      {
        img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
        title: 'Honey',
      },
      {
        img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
        title: 'Basketball',
      },
      {
        img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
        title: 'Fern',
      },
      {
        img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
        title: 'Mushrooms',
      },
      {
        img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
        title: 'Tomato basil',
      },
      {
        img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
        title: 'Sea star',
      },
      {
        img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
        title: 'Bike',
      },
    ];


const onGoToArticle1 = () => {
      const contentMain = document.querySelector('main');
      const anchor1 = document.querySelector('.article1');
      const anchorTop = props.viewportHeight > 430 ? 260 : 159;

      let a = contentMain.scrollTop + anchor1.getBoundingClientRect().top - anchorTop;
      contentMain.scrollTo({
        top: a,
        left: 0,
       behavior: 'smooth'
      });
    }        

const onGoToArticle2 = () => {

      const contentMain = document.querySelector('main');
      const anchor2 = document.querySelector('.article2');
      const anchorTop = props.viewportHeight > 430 ? 260 : 159;

      let b = contentMain.scrollTop + anchor2.getBoundingClientRect().top - anchorTop;
      contentMain.scrollTo({
        top: b,
        left: 0,
       behavior: 'smooth'
      });
    }        

const onGoToArticle3 = () => {

      const contentMain = document.querySelector('main');
      const anchor3 = document.querySelector('.article3');

      const anchorTop = props.viewportHeight > 430 ? 260 : 159;

      const additionalOffset = 20; 

      let c = contentMain.scrollTop + anchor3.getBoundingClientRect().top - anchorTop + additionalOffset ;
      contentMain.scrollTo({
        top: c,
        left: 0,
       behavior: 'smooth'
      });
    }  



    const onGoToArticle4 = () => {
      const contentMain = document.querySelector('main');
      const anchor4 = document.querySelector('.article4');
    
      const anchorTop = props.viewportHeight > 430 ? 260 : 159;
    
      // Offset für das letzte Menupunkt damit der Cursor springt
      const additionalOffset = 20; 
    
      let d = contentMain.scrollTop + anchor4.getBoundingClientRect().top - anchorTop + additionalOffset;
      contentMain.scrollTo({
        top: d,
        left: 0,
        behavior: 'smooth'
      });
    }    

const onBackToTopHandler = () => {

const contentMain = document.querySelector('main');
contentMain.scrollTo({
  top: 0,
  left: 0,
  behavior: 'smooth'
});
}


const aboutScholar = <div><p>
<strong>Ich bin promovierter Literaturwissenschaftler</strong> (Studium: Anglistik, Klassische Philologie und Amerikastudien) mit langjähriger Erfahrung als Dozent in der universitären Lehre, als wissenschaftlicher Autor, Rezensent, Herausgeber und Lektor. Bis zum Beginn meiner Selbständigkeit 2018 habe ich mein Studien- und Berufsleben überwiegend an der Ruhr-Universität Bochum verbracht, zuletzt als Wissenschaftlicher Mitarbeiter am Englischen Seminar. Andere Wirkungsstätten waren die TU Dortmund und die University of Missouri-Columbia.
</p>
<p>
Meine Forschungsschwerpunkte liegen in der englischen Renaissance/Frühen Neuzeit, dem langen 18. Jahrhundert, der Rezeptionsgeschichte sowie der Theorie und Praxis des Wortspiels. Hinzu kommt ein breites interdisziplinäres Interessensspektrum an literatur- und kulturwissenschaftlichen Themen (zum modernistischen Roman, zur postmodernen Literatur, zum Horror, zu Sherlock Holmes).
</p>
<p>
Zu meinen Publikationen gehören, neben zahlreichen Artikeln und Rezensionen, die Monographien <em>Monsters and the Poetic Imagination in "The Faerie Queene": "Most Ugly Shapes and Horrible Aspects"</em> (Manchester University Press, 2015; in zweiter Auflage 2019) und <em>From Chaucer’s Pardoner to Shakespeare’s Iago: Aspects of Intermediality in the History of the Vice</em> (Lang, 2009). Hinzu kommen verschiedene Herausgebertätigkeiten, insbesondere als Ko-Editor der wissenschaftlichen Zeitschrift <em>Medievalia et Humanistica</em> (mit Prof. Dr. Reinhold Glei) und als <em>corresponding editor</em> für den <em>Spenser Review</em>. Für diese Publikationen verfasse ich auch Rezensionen, ebenso für <em>The Review of English Studies</em> und das <em>Shakespeare-Jahrbuch</em>. Anderen Publikationen stehe ich als Peer-Reviewer zur Seite.
</p>

</div>;


return (
  <main className={styles.main} onScroll={checkActivePage}>
     
      {props.viewport > 430 &&
      <SideMenu 
      link1='About'
      link2='Forschung' 
      link3='Lehre' 
      link4='Bibliografie' 
      onLink1Click = {onGoToArticle1} 
      onLink2Click = {onGoToArticle2} 
      onLink3Click = {onGoToArticle3} 
      onLink4Click = {onGoToArticle4}
      isActive = {activePage}
      viewport = {props.viewport}
      viewportHeight = {props.viewportHeight}
      />}

      {(props.viewport > 430 && props.viewportHeight> 430) && <SocialMenu />}

      <Header 
      viewport={props.viewport}
      viewportHeight = {props.viewportHeight}
      color={props.viewport>430 ? 'rgba(138,228,220,1)' : 'black' }>
        Akademiker
        </Header>
<img src ={bitmap2} alt = "Maik Goth, trainer" className = {styles.picture} />

<section className={styles.content}> 
<article className='article1'>
  <h3 className={styles.articleTitle} style={{marginBottom: '60px'}}>Über mich als<span className={styles.titleShift}>AKADEMIKER</span></h3>
  <div className={styles.articleContent}>{aboutScholar}</div>
<BackToTop onBackToTop = {onBackToTopHandler}/>
</article>

<img src={separator} alt="separator line" className={styles.articleSeparator}/>

<article className='article2'>

  <h3 className={styles.articleTitleCenter}>FORSCHUNG</h3>
    <div className={styles.articleContent}>
      
      <p>
<strong>Für mich findet Forschung nicht im hermetischen Raum, sondern im offenen, interdisziplinären Austausch mit Kolleg:innen statt, Expert:innen wie Interessierten.</strong> Ein gutes Beispiel dafür ist das von der Deutschen Forschungsgemeinschaft geförderte Netzwerk "Dynamik des Wortspiels", an dem ich gemeinsam mit internationalen Kolleg:innen aus den Literatur- und Theaterwissenschaften, aus der Komparatistik und der Linguistik sowie mit Kreativen aus Deutschland, Großbritannien und Frankreich zusammengearbeitet habe. Zusätzlich zu Teilnahmen an Konferenzen und Netzwerktreffen habe ich für die achtbändige, bei de Gruyter publizierte Reihe <em>The Dynamics of Wordplay</em> originäre Forschung zum double entendre beigesteuert, einen Band als Mitherausgeber betreut und für den Praxisband Gedichte verfasst.
</p>
<p>
<strong>Wissenschaft verstehe ich als Kommunikation auf Diskursebene,</strong> in der unterschiedliche Themen, Ansätze und Persönlichkeiten miteinander in Kontakt treten. Dies drückt sich ferner darin aus, dass ich zahlreiche Vorträge und Vorlesungen auf internationalen Symposien zu Themen der englischen und amerikanischen Literatur sowie Kulturwissenschaften gehalten, Sitzungen geleitet und Tagungen mitorganisiert habe.
</p>

            
      
  </div>

  <BackToTop onBackToTop = {onBackToTopHandler}/>

</article>

<img src={separator} alt="separator line" className={styles.articleSeparator}/>

<article className='article3'>
  <h3 className={styles.articleTitleCenter}>LEHRE</h3>
  <div className={styles.articleContent}>
  <p>
<strong>Als Dozent an der Ruhr-Universität Bochum sowie an der University of Missouri-Columbia</strong> habe ich langjährige Erfahrung in der Erwachsenenbildung. Diese umfasst den gesamten universitären Unterricht von Übungen in kleinen Gruppen (5-10 Teilnehmer:innen) zu mittelgroßen Seminaren (30-50 Teilnehmer:innen) bis hin zu großen Vorlesungen (120 Teilnehmer:innen). Mein Lehrangebot ist gleichermaßen breit gefächert und umfasst die Literatur von der Antike bis in die Postmoderne, theaterwissenschaftliche Kurse zur Dramenanalyse und praktischen Inszenierung, Grammatik, Übersetzung, Academic Skills sowie fächerübergreifende Veranstaltungen (Klassische Philologie, Physik, Neurologie) vom Elementarunterricht bis zum Kurs im Masterstudium. Dabei habe ich mich immer von meiner großen Freude am Unterrichten, am Vermitteln von Begeisterung für die englische Sprache und ihre Literaturen sowie an der interaktiven Weitergabe von Wissen und <em>soft skills</em> leiten lassen. Zusätzlich habe ich mehrere Jahre das DAAD-Sprachzeugnis bei Studierenden wie Lehrenden gemäß dem "Gemeinsamen Europäischen Referenzrahmen" abgenommen.
</p>
<p>
Ich habe umfassend schriftliche Bachelorarbeiten in der Amerikanistik und der Anglistik betreut und begutachtet sowie mündliche Prüfungen abgenommen und Kurstypen aktiv konzipiert und gestaltet (»Introduction to English Literary Studies«, »Academic Skills«). Um meine didaktischen Fertigkeiten in Erwachsenenbildung und -unterricht zu vertiefen, habe ich an Kursen beim Institut für interne Fortbildung und Beratung (IFB) der Ruhr-Universität Bochum teilgenommen. Dazu gehörten Lehrproben, Supervision, interaktive Unterrichtsgestaltung und Methoden zur Fremdsprachenlehre.
</p>

      
      
  </div>
<BackToTop onBackToTop = {onBackToTopHandler}/>


</article>

<img src={separator} alt="separator line" className={styles.articleSeparator}/>

<article className='article4'>
<h3 className={styles.articleTitleCenter}>BIBLIOGRAFIE</h3>
    <div className={styles.articleContent}>
      <div className={styles.expandableTitle} 
      onClick={onContent1ExpandHandler}
      onKeyDown={onContent1ExpandHandler} 
        role="button" 
        tabIndex="0"
        aria-expanded={expandableBlock1Expanded}
        aria-controls="Monographien-section">
        <img src={backToTopArrow} className={expandableBlock1Expanded ? styles.arrowSmaller180g : styles.arrowSmaller90g} alt="Icon To Expand Monography Block"/> 
        <h4>MONOGRAPHIEN</h4>
      </div>
      <div className={expandableBlock1Expanded ? styles.expandableContent1Expanded : styles.expandableContent1}>

<div className={styles.werk}>
  <img src={monsters} className={styles.thumbnail} alt="Monsters and the Poetic Imagination"></img>
      <p>
  <em>Monsters and the Poetic Imagination in "The Faerie Queene": "Most Ugly Shapes, and Horrible Aspects"</em>. The Manchester Spenser. Manchester: Manchester University Press, 2015. Die zweite Auflage dieses Bandes wurde im Mai 2019 veröffentlicht.
</p>
<br />

<blockquote>
  "A great strength of Goth's study is its multiple appeal. This weighty contribution to literary studies will interest historians of fantasy, horror and the grotesque, of disability and of teratology, as well as specialists in Spenser, the literary debates of his time, or monsters in fiction. Supporting his text with a wealth of notes, identifying many unexpected contributions and references as well as most of the usual suspects, Goth reliably signposts the complex range of English and European monster traditions, myths and texts, raided, paraded, and upgraded by Spenser for his monsters […] [A] substantial contribution to Julian Lethbridge's prestigious series, The Manchester Spenser […], a solidly crafted resource for all serious Spenserians, the authoritative text in its field for years to come" (M.A. Katritzky, The Open University, The Spenser Review, May 2016).
</blockquote>
<blockquote>
  "A significant inquiry on monsters in Spenser's <em>Farie Queene</em>, [which] sheds new light on the representation of monstrosity in the Renaissance" (Daniela Carpi, <em>Anglistik – International Journal of English Studies 27.2 September 2016</em>)
</blockquote>
<blockquote>
  "at once learned and engaging […] In looking away from the allegorical heroes to consider its varied monsters, it offers a rich new perspective" (Kenneth Hodges, University of Oklahoma).
</blockquote>
</div>

<div className={styles.werk}>
  <img src={chaucer} className={styles.thumbnail} alt="From Chaucer's Pardoner to Shakespeare's Iago"></img>
<p>
  <em>From Chaucer's Pardoner to Shakespeare's Iago: Aspects of Intermediality in the History of the Vice</em>. Studien zur englischen und amerikanischen Literatur. Frankfurt/M. et al.: Lang, 2009.
</p>
<br />
<br />
<blockquote>
  "[An] excellent book" (Maurice Charney, Shakespeare's Villains. Madison, NJ: Fairleigh Dickinson University Press, 2012. 18n1)
</blockquote>
</div>

<p>
  als Herausgeber: <em>Crossing Languages to Play with Words: Multidisciplinary Perspectives</em>. ed. with Sebastian Knospe und Alexander Onysko. The Dynamics of Wordplay 3. Berlin: De Gruyter, 2016.
</p>



      </div>

      <div className={styles.expandableTitle} 
      onClick={onContent2ExpandHandler}
      onKeyDown={onContent2ExpandHandler} 
        role="button" 
        tabIndex="0"
        aria-expanded={expandableBlock2Expanded}
        aria-controls="Artikel-section">
        <img src={backToTopArrow} className={expandableBlock2Expanded ? styles.arrowSmaller180g : styles.arrowSmaller90g}
        alt="Icon To Expand Artikel Block"/> 
        <h4>ARTIKEL (Auswahl)</h4>
      </div>
      <div className={expandableBlock2Expanded ? styles.expandableContent2Expanded : styles.expandableContent2}>


      <div>
<p>
  "Double Entendre in Restoration and Early Eighteenth-Century Comedy." Wordplay and Metalinguistic/Metadiscursive Reflection: Authors, Contexts, Techniques, and Meta-Reflection. Ed. Angelika Zirker and Esme Winter-Froemel. The Dynamics of Wordplay 1. Berlin: de Gruyter, 2015. 71-94.
</p>

<p>
  "Exaggerating Terence's <em>Andria</em>: Steele's <em>The Conscious Lovers</em>, Bellamy's <em>The Perjur'd Devotee</em> and Terentian Criticism." <em>Ancient Comedy and Reception: Essays in Honor of Jeffrey Henderson</em>. Ed. S. Douglas Olson. Tübingen: de Gruyter, 2013. 503-536.
</p>
<div className={styles.werk}>
  <img src ={drama} className={styles.thumbnail} alt="Killing, Hewing, Stabbing, Dagger-drawing-Fighting, Butchery"></img>
<p>
  "'Killing, Hewing, Stabbing, Dagger-drawing, Fighting, Butchery': Skin Penetration in Renaissance Tragedy and Its Bearing on Dramatic Theory." <em>Comparative Drama</em> 46 (2012): 139-162.
</p>

</div>

<div className={styles.werk}>
<p>
  "Spenser As Prometheus: The Monstrous and Poetic Creation in the Early Modern Period." <em>Connotations 18</em> (2008/09): 182-207.
</p>
</div>

<p>
  "'Infinite Shapes of Things': Monsters and the Monstrous in Edmund Spenser's <em>The Faerie Queene</em>." <em>Clerks, Wives and Historians: Essays on Medieval Language and Literature.</em> Ed. Winfried Rudolf, Thomas Honegger and Andrew James Johnston. Variations 8. Bern et al.: Lang, 2008. 141-184.
</p>

<p>
  "John Lanchester's <em>The Debt</em> to <em>Pleasure</em> (1996): An Aesthetics of Textual Surprise." <em>Connotations</em> 14 (2004/2005): 135-161.
</p>
</div>

</div>

      <div className={styles.expandableTitle} 
      onClick={onContent3ExpandHandler}
      onKeyDown={onContent3ExpandHandler} 
        role="button" 
        tabIndex="0"
        aria-expanded={expandableBlock3Expanded}
        aria-controls="Debatte-section">
        <img src={backToTopArrow} className={expandableBlock3Expanded ? styles.arrowSmaller180g : styles.arrowSmaller90g} alt="Icon to expand DEBATTE Section"/> 
        <h4>DEBATTE</h4>
      </div>
      <div className={expandableBlock3Expanded ? styles.expandableContent3Expanded : styles.expandableContent3}>
      <div>
<p>
  Zu dem Artikel "Spenser As Prometheus: The Monstrous and Poetic Creation in the Early Modern Period" sind vier Beiträge bedeutender Renaissancewissenschaftler erschienen.
</p>
<ul>
  <li>Andrew Hadfield, "Spenser As Prometheus: A Response to Maik". <em>Connotations</em> 20 (2010/11): 189-200.</li>
  <li>John Watkins, "Spenser's Monsters: A Response to Maik Goth". <em>Connotations</em> 20 (2010/11): 201-09.</li>
  <li>Matthew Woodcock, "Elf-Fashioning Revisited: A Response to Maik Goth". <em>Connotations</em> 20 (2010/11): 210-20.</li>
  <li>Maurice Hunt, "Spenser's Monsters: A Response to Maik Goth". <em>Connotations</em> 21 (2011/12): 1-7.</li>
</ul>

</div>

      </div>

      <div className={styles.expandableTitle} 
      onClick={onContent4ExpandHandler}
      onKeyDown={onContent4ExpandHandler} 
        role="button" 
        tabIndex="0"
        aria-expanded={expandableBlock4Expanded}
        aria-controls="Rezensionen-section">
                      <img src={backToTopArrow} className={expandableBlock4Expanded ? styles.arrowSmaller180g : styles.arrowSmaller90g}
                      alt="Icon to expand REZENSIONEN Block"/> 
                      <h4>REZENSIONEN</h4>
                  </div>
                  <div className={expandableBlock4Expanded ? styles.expandableContent1Expanded : styles.expandableContent1}>
           <div className={styles.werk}>
<p>Publikationen in: <em>The Review of English Studies, The Spenser Review, Medievalia et Humanistica, Shakespeare Jahrbuch, Anglistik, Forum Modernes Theater</em></p>
</div>
<div className={styles.werk}>
<p>Themen: Dichtung und Drama der englischen Frühmoderne (insb. Edmund Spenser und William Shakespeare), Dichtung und Drama des englischen Mittelalters (z. B. <em>Beowulf</em>-MS, "Gawain-Poet", Chaucer), Mittellateinische Literatur (z. B. Bernardus Silvestris), Monstren (zeitgenössische und transhistorische Perspektiven), Körperdiskurs, Performativität, (Post-)Modernes Theater</p>
                  
</div>
</div>

                  {/* <div className={styles.expandableTitle} onClick={onContent5ExpandHandler}>
                      <img src={backToTopArrow} className={expandableBlock5Expanded ? styles.arrowSmaller180g : styles.arrowSmaller90g}/> 
                      <h4>EDITORISCHE ARBEIT</h4>
                  </div>
                  <div className={expandableBlock5Expanded ? styles.expandableContent5Expanded : styles.expandableContent5}>
                    <div className={styles.werk} >
                    <img src={medievalia} className={styles.thumbnail} style={{marginBottom: '32px'}}></img>
                  <p style={{marginBottom: '20px'}}>mit Reinhold F. Glei:  <em>Medievalia et Humanistica: Studies in Medieval and Renaissance Culture, New Series.</em> Lanham: Rowman and Littlefield; seit 2015.</p>
                  </div>
                  </div>

                  <div className={styles.expandableTitle} style={{clear: 'left'}}onClick={onContent6ExpandHandler}>
                      <img src={backToTopArrow} className={expandableBlock6Expanded ? styles.arrowSmaller180g : styles.arrowSmaller90g}/> 
                      <h4>MEDIEN</h4>
                  </div>
                  <div className={expandableBlock6Expanded ? styles.expandableContent6Expanded : styles.expandableContent6}>
                  {modalIsOn && ReactDOM.createPortal(<Backdrop isOn={modalIsOn}/>, backdrop) }
      {modalIsOn && ReactDOM.createPortal(
          <ImageModal 
              itemData = {itemData} 
              imageInModal = {itemData[displayedImageInModal].img} 
              imageId={displayedImageInModal}
              howManyImages = {itemData.length}
              onModalClose = {onClickOnX} 
              onArrowClick = {imageChangeHandler}/>,
          imageViewer)}
<StandardImageList onImageClick = {imageClickHandler} itemData = {itemData} />
                  </div> */}


    </div>
    <BackToTop onBackToTop = {onBackToTopHandler}/>


</article>

<Footer color={props.viewport > 430 ? 'rgba(138,228,220,1)' : 'transparent'}/>

</section>

{(props.viewport <= 430 && props.viewportHeight > 430) &&
      <SideMenu 
      link1='ABOUT' 
      link2='FORSCHUNG' 
      link3='LEHRE' 
      link4='BIBLIO' 
      homeLinkColor = '#fff245'
      onLink1Click = {onGoToArticle1} 
      onLink2Click = {onGoToArticle2} 
      onLink3Click = {onGoToArticle3} 
      onLink4Click = {onGoToArticle4}
      isActive = {activePage}
      viewport = {props.viewport}
      />}

</main>
  );

}

export default Scholar;