import styles from './lyricist.module.css';
import { useState } from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';
import SideMenu from '../components/SideMenu';
import SocialMenu from '../components/SocialMenu';
import BackToTop from '../components/UI/BackToTop';
// import StandardImageList from '../components/UI/StandardImageList';
// import ImageModal from '../components/UI/ImageModal';
// import Backdrop from '../components/UI/Backdrop';

import bitmap1 from '../assets/images/bitmap_lyricist.png';
import separator from '../assets/images/article_separator.svg';
import backToTopArrow from '../assets/images/back_to_top.svg';
import wortspiel from '../assets/images/mg_autor_wortspiel.jpg';
import newYork from '../assets/images/mg_autor_new_york_2021.jpg';
import entdecker from '../assets/images/mg_autor_endteckerkalender.jpg';
import duden2024 from '../assets/images/mg_autor_duden_2024.jpg';

import { Link } from 'react-router-dom';

const Lyricist = (props) => {

    const backdrop = document.getElementById('backdrop');

    const imageViewer = document.getElementById('imageViewer');

    const [modalIsOn, setModalIsOn] = useState(false);
    const [displayedImageInModal, setDisplayedImageInModal] = useState(null);
    const [activePage, setActivePage] = useState('link2');

    // States for expandable content in article4
    const [expandableBlock1Expanded, setExpandableBlock1Expanded] = useState(false);
    const [expandableBlock2Expanded, setExpandableBlock2Expanded] = useState(false);
    const [expandableBlock3Expanded, setExpandableBlock3Expanded] = useState(false);

    // Handlers for expandable content in article4
    const onContent1ExpandHandler = () => {
      setExpandableBlock1Expanded(!expandableBlock1Expanded);
    }

    const onContent2ExpandHandler = () => {
      setExpandableBlock2Expanded(!expandableBlock2Expanded);
    }

    const onContent3ExpandHandler = () => {
      setExpandableBlock3Expanded(!expandableBlock3Expanded);
    }

    const checkActivePage = () => {
      const contentMain = document.querySelector('main');
      const anchor1 = document.querySelector('.article1').getBoundingClientRect();
      const anchor2 = document.querySelector('.article2').getBoundingClientRect();
      const anchor3 = document.querySelector('.article3').getBoundingClientRect();
      const anchor4 = document.querySelector('.article4').getBoundingClientRect();

      const anchorTop = props.viewportHeight > 430 ? 260 : 161;

      if(anchor4.top <= anchorTop) {
        setActivePage('link5')
      }else if(anchor3.top <= anchorTop) {
        setActivePage('link4') 
        }else if(anchor2.top <= anchorTop) {
        setActivePage('link3')
        }else if(anchor1.top <= anchorTop){
          setActivePage('link2');
        } else setActivePage('');
      }

      


  


    const imageClickHandler = (image) => {
      setModalIsOn(true);
      setDisplayedImageInModal(image);
      }

      const onClickOnX = () => {
        setModalIsOn(false);
    }

    const imageChangeHandler = (image) => {
        setDisplayedImageInModal(image);
    }

    // const itemData = [
    //     {
    //       img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    //       title: 'Breakfast',
    //     },
    //     {
    //       img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    //       title: 'Burger',
    //     },
    //     {
    //       img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    //       title: 'Camera',
    //     },
    //     {
    //       img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    //       title: 'Coffee',
    //     },
    //     {
    //       img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    //       title: 'Hats',
    //     },
    //     {
    //       img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    //       title: 'Honey',
    //     },
    //     {
    //       img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    //       title: 'Basketball',
    //     },
    //     {
    //       img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    //       title: 'Fern',
    //     },
    //     {
    //       img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    //       title: 'Mushrooms',
    //     },
    //     {
    //       img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    //       title: 'Tomato basil',
    //     },
    //     {
    //       img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    //       title: 'Sea star',
    //     },
    //     {
    //       img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    //       title: 'Bike',
    //     },
    //   ];


const onGoToArticle1 = () => {
        const contentMain = document.querySelector('main');
        const anchor1 = document.querySelector('.article1');
        const anchorTop = props.viewportHeight > 430 ? 260 : 159;

        let a = contentMain.scrollTop + anchor1.getBoundingClientRect().top - anchorTop;
        contentMain.scrollTo({
          top: a,
          left: 0,
         behavior: 'smooth'
        });
      }        

const onGoToArticle2 = () => {

        const contentMain = document.querySelector('main');
        const anchor2 = document.querySelector('.article2');
        const anchorTop = props.viewportHeight > 430 ? 260 : 159;

        let b = contentMain.scrollTop + anchor2.getBoundingClientRect().top - anchorTop;
        contentMain.scrollTo({
          top: b,
          left: 0,
         behavior: 'smooth'
        });
      }        

const onGoToArticle3 = () => {

        const contentMain = document.querySelector('main');
        const anchor3 = document.querySelector('.article3');

        const anchorTop = props.viewportHeight > 430 ? 260 : 159;

        let c = contentMain.scrollTop + anchor3.getBoundingClientRect().top - anchorTop ;
        contentMain.scrollTo({
          top: c,
          left: 0,
         behavior: 'smooth'
        });
      }  



      const onGoToArticle4 = () => {
        const contentMain = document.querySelector('main');
        const anchor4 = document.querySelector('.article4');
      
        const anchorTop = props.viewportHeight > 430 ? 260 : 159;
      
        // Offset für das letzte Menupunkt damit der Cursor springt
        const additionalOffset = 20; 
      
        let d = contentMain.scrollTop + anchor4.getBoundingClientRect().top - anchorTop + additionalOffset;
        contentMain.scrollTo({
          top: d,
          left: 0,
          behavior: 'smooth'
        });
      }    

const onBackToTopHandler = () => {

  const contentMain = document.querySelector('main');
  contentMain.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}



return (
    <main className={styles.main} onScroll={checkActivePage}>
       
        {props.viewport > 430 &&
          <SideMenu 
        link1='Autor'
        link2='Publikationen' 
        link3='Interessen' 
        link4='Docs' 
        onLink1Click = {onGoToArticle1} 
        onLink2Click = {onGoToArticle2} 
        onLink3Click = {onGoToArticle3} 
        onLink4Click = {onGoToArticle4}
        isActive = {activePage}
        viewport = {props.viewport}
        viewportHeight = {props.viewportHeight}
        />
       }

        {(props.viewport > 430 && props.viewportHeight> 430) && 
        <SocialMenu />}

        <Header 
        viewport={props.viewport}
        viewportHeight = {props.viewportHeight}
        color={props.viewport>430 ? '#fff245' : 'black' }>
          Autor
          </Header>
<img src ={bitmap1} alt = "Maik Goth, lyricist" className = {styles.picture} />

<section className={styles.content}> 
<article className='article1'>
    <h3 className={styles.articleTitle} style={{marginBottom: '60px'}}>Über mich als <span className={styles.titleShift}>AUTOR</span></h3>
    <div className={styles.articleContent}><p>Herzlich Willkommen!</p>
<p>Ich bin freiberuflicher Autor zwischen Unterhaltung und Wissenschaft. In meinen kreativen Arbeiten verbinde ich Fabulierfreude mit Wortgewandtheit und Verve. Dabei erweist mir mein akademischer Hintergrund (Doktortitel in Anglistik; Magister in Anglistik, Klassischer Philologie und Amerikastudien), die damit einhergehende Bildung und Arbeitsweise sowie meine Erfahrung als Autor und Herausgeber treue Dienste. Meine akademischen Publikationen finden Sie <Link to="../scholar">hier</Link>.</p>
<p>Ich bin promovierter Literaturwissenschaftler (Studium: Anglistik, Amerikastudien und Klassische Philologie) mit jahrzehntelanger Erfahrung als wissenschaftlicher Autor, Rezensent, Herausgeber und Lektor, sowie als Textdichter, Komponist und Musiker. </p>
<p>Als wissenschaftlicher Autor habe ich mich auf Fachtexte spezialisiert und bin auch als Rezensent tätig. Des Weiteren habe ich als Herausgeber und Lektor gearbeitet, wobei ich sowohl als Begutachter als auch als Korrekturleser fungierte. Mein Schaffen erstreckt sich zudem auf den Bereich der (pop-)kulturellen und kreativen Textgestaltung. Zudem bin als Textzusammensteller tätig, wobei ich zuletzt Texte für die Athesia Literaturkalender New York für die Jahre 2020 und 2021 ausgewählt habe.</p>
<p>Seit 2019 arbeite ich für Athesia/Harenberg in Verbindung mit dem Duden-Verlag an dem jährlich erscheinenden Kalender »Vergessene Wortschätze«, für den ich Etymologisches mit charmant-frechen Geschichten verbinde. Dabei schlage ich den Bogen zwischen Begriffen, die völlig vergessen sind (<em>Cochonerie</em>, <em>Hochzeitsbitter</em>), über solche, die gerade vergessen werden (blümerant, Fisimatenten), bis hin zu solchen, die uns geläufig sind, ohne dass wir ihre wörtliche Bedeutung noch kennen (Schmöker, piesacken). Dabei tritt immer wieder Erstaunliches und Bemerkenswertes zu Tage. Zahlreiche Leser:innen schreiben mir seither ihre Gedanken und Ideen, im Einzelhandel steht er Jahr für Jahr im Kalenderregal. </p>
<p>Für die Reihe <em>Spiegel Geschichte</em> verfasse ich einen Wochenkalender, in dem ich Entdecker:innen und ihre Abenteuer präsentiere. 2023 porträtiere ich Menschen wie Queen Bess, Marie Curie, Matthew Henson, Tenzing Norgay, Tanaka Shosuke und Estevanico, berichte über Funde wie die Venus von Willendorf und die Himmelsscheibe von Nebra. Dazu gibt's Apollo 11, Lawrence von Arabien, Fridtjof Nansen, Magellan und viele mehr. 
Zudem publiziere ich Lyrik und bin Textdichter in deutscher und englischer Sprache.</p>
<p>Ich freue mich darauf, von Ihnen zu hören!</p>
</div>
<BackToTop onBackToTop = {onBackToTopHandler}/>
</article>

<img src={separator} alt="separator line" className={styles.articleSeparator}/>

<article className='article2'>

    <h3 className={styles.articleTitleCenter}>PUBLIKATIONEN</h3>
    <div className={styles.articleContent}>
    <p><strong>Kalender</strong></p>
    <div className={styles.werk}>
      <img src = {duden2024} className={styles.thumbnail} alt="Duden Kalender"></img>
<p><em>Duden Vergessene Wortschätze</em> — bislang sechs Editionen seit 2021</p>
</div>
<div className={styles.werk}>
  <img src = {entdecker} className={styles.thumbnail} alt="Spiegel Geschichte: Große Abenteurer und Entdecker"></img>
<p><em>Spiegel Geschichte Große Abenteurer und Entdecker</em> — 2022-2023</p>
</div>
<div className={styles.werk}>
  <img src={newYork} className={styles.thumbnail} alt="Literaturkalender New York - 2020-2021"></img>
<p><em>Literaturkalender New York</em> — 2020-2021</p>
</div>
<div className={styles.werk}>
<p><strong>Gedichte</strong></p>
<p>»Im Wortex: Eine philologisch-kritische Würdigung der Dichtung Michael J. Schönens« &</p>
</div>

<div className={styles.werk}>
<img src ={wortspiel} className={styles.thumbnail} alt="»Typologie des Wortspiels: Ein Lehrgedicht to go«"></img>
<p>»Typologie des Wortspiels: Ein Lehrgedicht to go«.</p>
<p>in <em>Sprach-Spiel-Kunst</em>. Ed. Esme Winter-Froemel. The Dynamics of Wordplay 8. Berlin: De Gruyter, 2019. S. 165 und S. 263f.</p>
</div>
<div className ={styles.werk}>
<p>»Im Büro. Dienstag, 12:28:37« in: <em>DEW 21 Kultur</em>.</p>
</div>

</div>

    <BackToTop onBackToTop = {onBackToTopHandler}/>

</article>

<img src={separator} alt="separator line" className={styles.articleSeparator}/>

<article className='article3'>
    <h3 className={styles.articleTitleCenter}>INTERESSEN</h3>
    <div className={styles.articleContent}><p><strong>In Literatur und Kultur</strong> erstreckt sich mein Interesse (auch aufgrund meiner Arbeit in Forschung und Lehre) von der Antike bis zur Postmoderne, insbesondere die anglophone Literatur. Dabei bewege ich mich zwischen allen literarischen Gattungen (Epos, Lyrik, Drama, Erzählung, Roman) und spare auch sogenannte <em>genre fiction</em> (z.B. Horror und Fantasy) nicht aus. Besonderes Augenmerk liegt dabei auf den Elisabethanischen und Jakobäischen Zeitaltern (Theater, Lyrik, Zeitgeschehen) und dem viktorianischen England (Literatur, Zeitgeschehen). Mit großer Leidenschaft befasse ich mit dem Thema »Sherlock Holmes: Von den Vorläufern bis ins frühe 21. Jhdt« und dem »klassischen« Detektivroman sowie seinen transkulturellen Nachfahren, wie dem japanischen Honkaku. In der deutschen Literatur hat es mir die humoreske Lyrik von Heinrich Heine bis Robert Gernhardt angetan. Auch städtischen Lebensräumen habe ich mich gewidmet, besonders den Großstädten New York, London, Paris und Rom.</p>

<p><strong>Seit einigen Jahren</strong> befasse ich mich intensiv mit Comics und der sogenannten »Graphic Novel« von den Anfängen (z.B. Winsor McCay) bis zur Gegenwart, vom Großverlag bis zum Independent Publishing, sowohl national als auch international (anglo-amerikanisch, franko-belgisch, japanisch). Besonders fasziniert mich serielles Erzählen im Zusammenspiel von Wort und Bild sowie die Bildgestaltung.</p>

<p><strong>Als leidenschaftlicher Musiker</strong> mit einem Standbein in Recherche und Forschung habe ich mich mit zahlreichen Erscheinungsformen der Musik auseinandergesetzt. Dazu gehören Alte Musik, Klassik und Neue Musik genauso wie Rock'n'Roll, Roots Music (Bluegrass, Country, Blues) und Globale Musik bzw. Weltmusik. In diesen populären Genres sowie in Jazz, Soul und Popmusik habe ich auch weitgreifende Kompositions- und Bühnenerfahrung gesammelt.</p>

<p><strong>Auch für Lebensgeschichten</strong> von Kulturschaffenden interessiere ich mich, besonders für Autor:innen, Musiker:innen, Singer-Songwriter:innen, Komponist:innen und Schauspieler:innen an der Schnittstelle von historisch-kulturellen Bezügen und persönlichem Schaffen.</p>

<p><strong>Seit Kindesbeinen</strong> begeistere ich mich für Naturwelten. Dazu gehören allgemeine Themen wie Meer, Wald, Tierwelt und Garten, sowie ganz besonders Meeressäugetiere. Eines meiner bedeutendsten Erlebnisse war die Teilnahme an einer einwöchigen Expedition nach Nordnorwegen, auf der ich aus nächster Nähe Orcas und Buckelwale beim Heringjagen beobachten durfte.</p>
           
<p><strong>Andere Steckenpferde</strong> sind Tanz (besonders klassisches und modernes Ballett) sowie Stummfilm, Film Noir, Horror und Animationsfilm.</p>
</div>
<BackToTop onBackToTop = {onBackToTopHandler}/>


</article>

<article className='article4'>
      <h3 className={styles.articleTitleCenter}>DOCS</h3>
      <div className={styles.articleContent}>
        <div className={styles.expandableTitle} 
        onClick={onContent1ExpandHandler} 
        onKeyDown={onContent1ExpandHandler} 
        role="button" 
        tabIndex="0"
        aria-expanded={expandableBlock1Expanded}
        aria-controls="leserstimmen-section">
          <img src={backToTopArrow} className={expandableBlock1Expanded ? styles.arrowSmaller180g : styles.arrowSmaller90g} alt="Back to Top Icon"/> 
          <h4>LESER:INNENSTIMMEN</h4>
        </div>
        <div className={expandableBlock1Expanded ? styles.expandableContent1Expanded : styles.expandableContent1}>

  
  <p><strong>Zum Dudenkalender</strong></p>
  <blockquote>
    <em>»Sechsmal pro Woche begegnen die Besitzer des Kalenders Begriffen wie 'Spargeltarzan' oder 'Rosinenbomber' und erfahren in kurzen, amüsanten Texten wertvolle Hintergrundinformationen.«</em> - Westfälischer Anzeiger
  </blockquote>
  
  <blockquote>Leser:innenstimmen (amazon.de):</blockquote>
  <blockquote>
    <em>»Total interessant, wie viele Ausdrücke man ständig verwendet, ohne sich bewusst zu machen, woher diese Wörter kommen und welche ursprüngliche Bedeutung sie hatten. Immer klasse, ausführlich und fundiert beschrieben, nicht zu viel sondern gerade richtig. Mit netten kleinen abschließenden Anekdoten dazu. Muss man natürlich mögen.«</em> - (Luzy)
  </blockquote>
  <blockquote>
    <em>»Der Kalender ist eines dieser Geschenke, bei denen man hinterher merkt, dass man es sich am liebsten selbst geschenkt hätte... ... mehr muss dazu man nicht sagen, denke ich! Ein richtig starkes Produkt, dass einem tägliche Freude, Erstaunen, Gelächter und neues Wissen beschert. Bin begeistert und werde ihn mir wahrscheinlich noch in diesem Jahr selbst zu legen. Gerade im schulischen bzw. Betreuungssektor ist der Kalender eine echte Bereicherung. Sobald die derzeit in Herstellung befindliche Collage fertig ist, liefere ich ein Foto nach... 10/10 Absolute Kaufempfehlung!«</em> - (vinzenz_m)
  </blockquote>
  <blockquote>
    <em>»This calendar is lots of fun. Very interesting vocab tidbits and some of the entries are very cheekily written. I love it.«</em> - (Zmrzlina)
  </blockquote>
  <blockquote>
    <em>»Erstaunlich, was alles so nach und nach aus unserem Sprachschatz fällt. Ja, es ist ein Schatz, und jeden Tag gibt es ein erstauntes Schauen, Nachdenken (habe ich doch neulich noch gehört?) Und Wundern über gesellschaftliche Entwicklungen, die sich auch in der Sprache niederschlagen. Gekauft als Geschenk schenkt dieser Kalender jeden Morgen nun ein kleines Innehalten, Nachdenken und: Lächeln.«</em> - (Elke)
  </blockquote>

  <p><strong>Zum New-York-Kalender</strong></p>
  <blockquote>
    <em>»Man entdeckt in diesem Kalender immer wieder etwas Neues, was diese Metropole so einzigartig macht.«</em> - (Carl-Heinrich Bock)
  </blockquote>



        </div>

        <div className={styles.expandableTitle} 
        onClick={onContent2ExpandHandler} 
        onKeyDown={onContent2ExpandHandler} 
        role="button" 
        tabIndex="0"
        aria-expanded={expandableBlock2Expanded}
        aria-controls="links-section">
          <img src={backToTopArrow} className={expandableBlock2Expanded ? styles.arrowSmaller180g : styles.arrowSmaller90g} alt="Back to top icon"/> 
          <h4>LINKS</h4>
        </div>
        <div className={expandableBlock2Expanded ? styles.expandableContent2Expanded : styles.expandableContent2}>




<blockquote>
  <a href="https://www.wa.de/lokales/boenen/sprachbesoffener-dr-maik-goth-aus-boenen-ist-ein-hueter-verlorener-wortschaetze-92177143.html" target="_blank" rel="noopener noreferrer">
    <em>„Sprachbesoffener“ Dr. Maik Goth ist ein Hüter verlorener Wortschätze</em> (wa.de)
  </a>
</blockquote>

<blockquote>
  <a href="https://leselebenszeichen.wordpress.com/tag/dr-maik-goth/" target="_blank" rel="noopener noreferrer">
    <em>DUDEN: Vergessene Wortschätze 2024</em> (Leselebenszeichen)
  </a>
</blockquote>



        </div>

       {/*  <div className={styles.expandableTitle} onClick={onContent3ExpandHandler} onKeyDown={onContent3ExpandHandler} role="button" tabIndex="0"
       aria-expanded={expandableBlock3Expanded}
        aria-controls="pictures-section">
          <img src={backToTopArrow} className={expandableBlock3Expanded ? styles.arrowSmaller180g : styles.arrowSmaller90g}/> 
          <h4>BILDER</h4>
        </div>
        <div className={expandableBlock3Expanded ? styles.expandableContent3Expanded : styles.expandableContent3}>
        {modalIsOn && ReactDOM.createPortal(<Backdrop isOn={modalIsOn}/>, backdrop) }
        {modalIsOn && ReactDOM.createPortal(
            <ImageModal 
                itemData = {itemData} 
                imageInModal = {itemData[displayedImageInModal].img} 
                imageId={displayedImageInModal}
                howManyImages = {itemData.length}
                onModalClose = {onClickOnX} 
                onArrowClick = {imageChangeHandler}/>,
            imageViewer)}
<StandardImageList onImageClick = {imageClickHandler} itemData = {itemData} />
<BackToTop onBackToTop = {onBackToTopHandler}/>
        </div> */}
      </div>
      <BackToTop onBackToTop = {onBackToTopHandler}/>
    </article> 
    

<Footer color={props.viewport > 430 ? '#fff245' : 'transparent'}/>

</section>

{(props.viewport <= 430 && props.viewportHeight > 430) &&
        <SideMenu 
        link1='AUTOR' 
        link2='WERK' 
        link3='INTERESSEN' 
        link4='DOCS' 
        homeLinkColor = '#fff245'
        onLink1Click = {onGoToArticle1} 
        onLink2Click = {onGoToArticle2} 
        onLink3Click = {onGoToArticle3} 
        onLink4Click = {onGoToArticle4}
        isActive = {activePage}
        viewport = {props.viewport}
        />}

</main>

);
}
export default Lyricist;