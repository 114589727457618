import styles from './backToTop.module.css';
import backToTopArrow from '../../assets/images/back_to_top.svg';

const BackToTop = (props) => {


    return(
        <div className={styles.container} onClick = {props.onBackToTop} onKeyDown={props.onBackToTop} role="button" tabIndex="0" aria-label="Back to Top">
            <div className={styles.text}>
                Back to TOP
            </div>
            <img src={backToTopArrow} className={styles.picture} alt="Back to Top Icon" />

        </div>
    );

}

export default BackToTop;