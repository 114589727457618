import styles from './socialmenu.module.css';
import fb from '../assets/images/f_logo.svg';
import insta from '../assets/images/i_logo_black.svg';
import xBlack from '../assets/images/logo-x-black.svg';

const SocialMenu = () => {
    return (
        <nav className={styles.container} aria-label="Social Media Navigation">
            {/* <img src={fb} className={styles.fbLogo} alt="facebook logo" /> */}
            <a className={styles.inLink} href="https://www.instagram.com/maikgoth/"> 
   <img src={insta} className={styles.inLogo} alt="instagram logo" />
   </a>
   <a className={styles.xLink} href="https://x.com/GothMaik"> 
    <img src={xBlack} className={styles.xLogo} alt="X logo" />
    </a>
        </nav>
    );


}

export default SocialMenu;